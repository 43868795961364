<template lang="pug">
widget-base(
  :title='name',
  :button-props='buttonProps',
  :col-props='{ lg: "8" }',
  :refresh='refresh',
  :error='error',
  :has-data='items.length > 0',
  :loading='false'
)
  //- ERRORS
  v-card-text(v-if='error')
    v-alert.mb-0(type='error' style='overflow: auto')
      | {{ error.message }}

  v-data-table(:headers='headers', :items='items', :loading='loading')
    //- Hide the footer
      replaces the 'hide-default-footer' prop from v2
    template(#bottom)

    template(#item.timestamp='{ value }')
      | {{ formatISODate(value) }}
</template>

<script lang="ts" setup>
import type { RouteLocationNamedRaw } from 'vue-router'
import type { DataTableHeader } from 'vuetify/components/VDataTable'

import type { BullJobListFetchFn, BullJobListItem } from '@/api/types'
import type { DeepConcatKeys } from '@/types'
import { formatISODate } from '@/utils'

type ColumnsFromData<
  DataType,
  JobType extends BullJobListItem = BullJobListItem<DataType>,
> =
  | keyof Omit<JobType, 'stacktrace' | 'returnvalue'>
  | `data.${DeepConcatKeys<DataType>}`

export type JobHeader<DataType> = DataTableHeader & {
  value: ColumnsFromData<DataType>
}

export type Props<DataType = any> = {
  name: string
  listFn: BullJobListFetchFn<DataType>
  headers: JobHeader<DataType>[]
  viewRoute: RouteLocationNamedRaw
  uploadRoute: RouteLocationNamedRaw
}

const props = defineProps<Props>()

const buttonProps = {
  prependIcon: 'mdi-upload',
  to: props.uploadRoute,
  text: 'Upload CSV',
}

const refresh = () => {
  _refresh({
    end: 5,
    desc: true,
  })
}

const {
  loading,
  data,
  error,
  refresh: _refresh,
} = useSafeComponentStore(props.listFn)
const items = computed(() => {
  if (!data.value || !data.value) return []
  return data.value.data.map(job => {
    return {
      ...job,

      color: 'primary',
      to: {
        name: props.viewRoute.name,
        query: {
          ...props.viewRoute.query,
          id: job.id,
        },
      },
    }
  })
})
refresh()
</script>
