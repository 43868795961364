export * from './branding'
export * from './csv-to-json'
export * from './typecast-v-form'

/**
 * Stringify fields on an object as JSON
 * Does not support nested objects
 *
 * @param obj The object to stringify
 * @param fields The fields to stringify
 * @returns a shallow clone of the object with the fields stringified
 */
export function serializeFieldsAsJSON(
  obj: Record<string, any>,
  fields: string[]
) {
  const serialized = { ...obj }
  for (const field of fields) {
    const value = serialized[field]
    if (!value) continue

    serialized[field] = JSON.stringify(value)
  }
  return serialized
}

/**
 * Given an object, returns a list of keys sorted alphabetically, but with the
 * keys in the prefix list first.
 *
 * @param obj The object to sort.
 * @param prefix The fields to place first in the order
 * @returns The sorted keys
 *
 * @example
 * getSortedParamOrder({ c: 1, a: 1, f: 1, b: 1 }, ['f', 'a']) // ['f', 'a', 'b', 'c']
 */
export function getSortedParamOrder(obj: any, prefix: string[] = []) {
  const order = Object.keys(obj).sort((a, b) => {
    const aIndex = prefix.indexOf(a)
    const bIndex = prefix.indexOf(b)
    if (aIndex >= 0) {
      if (bIndex >= 0) {
        return aIndex - bIndex
      }
      return -1
    }
    if (bIndex >= 0) {
      return 1
    }

    return a.localeCompare(b)
  })
  return order
}

/**
 * A friendlier ISO datetime string
 */
export const formatISODate = (date?: string | Date) => {
  if (!date) return ''
  return new Date(date).toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  })
}

/** 750ms is long enough to avoid the initial gap when holding a button */
export const DEFAULT_DEBOUNCE_DELAY = 750

/**
 * Absolutely basic debounce function
 *
 * @param fn The function to debounce
 * @param delay The delay in milliseconds or true for the default delay and
 * false to return `fn` unchanged
 *
 * @see https://stackoverflow.com/a/53486112
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends (...args: any[]) => any>(
  this: unknown,
  fn: T,
  delay?: boolean | number
) {
  if (!delay) return fn
  if (typeof delay !== 'number') delay = DEFAULT_DEBOUNCE_DELAY
  let timeoutID: number | NodeJS.Timeout
  return ((...args: any[]) => {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => {
      fn.apply(this, args)
    }, delay as number)
  }) as T
}
