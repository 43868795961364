<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
    <g transform="translate(20.15,12.953404)">
      <path
        fill="#95bf47"
        d="m 95.9,23.9 c -0.1,-0.6 -0.6,-1 -1.1,-1 -0.5,0 -9.3,-0.2 -9.3,-0.2 0,0 -7.4,-7.2 -8.1,-7.9 -0.7,-0.7 -2.2,-0.5 -2.7,-0.3 0,0 -1.4,0.4 -3.7,1.1 -0.4,-1.3 -1,-2.8 -1.8,-4.4 -2.6,-5 -6.5,-7.7 -11.1,-7.7 0,0 0,0 0,0 -0.3,0 -0.6,0 -1,0.1 C 57,3.4 56.8,3.3 56.7,3.1 54.7,0.9 52.1,-0.1 49,0 43,0.2 37,4.5 32.2,12.2 c -3.4,5.4 -6,12.2 -6.8,17.5 -6.9,2.1 -11.7,3.6 -11.8,3.7 -3.5,1.1 -3.6,1.2 -4,4.5 -0.3,2.5 -9.5,73 -9.5,73 l 76.4,13.2 33.1,-8.2 C 109.5,115.8 96,24.5 95.9,23.9 Z M 67.2,16.8 c -1.8,0.5 -3.8,1.2 -5.9,1.8 0,-3 -0.4,-7.3 -1.8,-10.9 4.5,0.9 6.7,6 7.7,9.1 z m -10,3.1 c -4,1.2 -8.4,2.6 -12.8,3.9 1.2,-4.7 3.6,-9.4 6.4,-12.5 1.1,-1.1 2.6,-2.4 4.3,-3.2 1.8,3.5 2.2,8.4 2.1,11.8 z M 49.1,4 c 1.4,0 2.6,0.3 3.6,0.9 C 51.1,5.8 49.5,7 48,8.6 44.2,12.7 41.3,19.1 40.1,25.2 36.5,26.3 32.9,27.4 29.6,28.4 31.7,18.8 39.8,4.3 49.1,4 Z"
      />
      <path
        fill="#5e8e3e"
        d="m 94.8,22.9 c -0.5,0 -9.3,-0.2 -9.3,-0.2 0,0 -7.4,-7.2 -8.1,-7.9 -0.3,-0.3 -0.6,-0.4 -1,-0.5 V 124 l 33.1,-8.2 c 0,0 -13.5,-91.3 -13.6,-92 -0.1,-0.5 -0.6,-0.9 -1.1,-0.9 z"
      />
      <path
        fill="#ffffff"
        d="m 58,39.9 -3.8,14.4 c 0,0 -4.3,-2 -9.4,-1.6 -7.5,0.5 -7.5,5.2 -7.5,6.4 0.4,6.4 17.3,7.8 18.3,22.9 0.7,11.9 -6.3,20 -16.4,20.6 C 27,103.4 20.3,96.2 20.3,96.2 l 2.6,-11 c 0,0 6.7,5.1 12.1,4.7 3.5,-0.2 4.8,-3.1 4.7,-5.1 C 39.2,76.4 25.4,76.9 24.5,63.1 23.8,51.5 31.4,39.7 48.2,38.7 54.7,38.2 58,39.9 58,39.9 Z"
      />
    </g>
  </svg>
</template>
