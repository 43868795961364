import { cloneDeep } from 'lodash'

import type { DataModel } from '@/composables/useCsvValidation'
import type { RawNetSuiteItem, NetSuiteItem } from '@/types'
import { injectedColumnsKeys } from '@/utils'

export const removeInjectedProps = (item: any) => {
  const itemCopy = cloneDeep(item)

  // Remove injected props which shouldn't be a part of the request
  delete itemCopy[injectedColumnsKeys.message]
  delete itemCopy[injectedColumnsKeys.invalidValues]

  // Remove props which may have been added by NetSuite
  delete itemCopy.error

  return itemCopy
}

export const toSimpleKeyValue = (dataModel: DataModel, item: any) => {
  return Object.keys(item)
    .map(key => {
      let value = item[key]
      // Sometimes the value is nested
      if (typeof value === 'object') {
        value = item[key].value
      }

      // Map the key if there is a mapping provided in the data model
      let mappedKey = dataModel[key] ? dataModel[key].prop : key

      // Convert all non-injected keys to lowercase
      if (Object.keys(injectedColumnsKeys).indexOf(key) === -1) {
        mappedKey = mappedKey.toLowerCase()
      }

      return {
        [mappedKey]: value, // NetSuite is expecting lowercase keys
      }
    })
    .reduce((item, keyValue) => {
      return {
        ...item,
        ...keyValue,
      }
    }, {})
}

/**
 * Simplifies raw NetSuite items to a more usable format
 * Type T represents the shape of the "values" property of the raw NetSuite item
 */
export function transformRawNetSuiteItem<T>(
  item: RawNetSuiteItem<T>
): NetSuiteItem<T> {
  const { values, ...itemData } = item

  const simplified =
    // Transform and reconstruct the object
    Object.entries(values).reduce((item, [k, v]) => {
      // Extract data from single item arrays
      // These are usually NetSuiteRecords
      if (Array.isArray(v)) {
        if (v.length === 1)
          return {
            ...item,
            [k]: v[0],
          }

        return {
          ...item,
          [k]: undefined,
        }
      }

      // All other values are passed through untouched
      return {
        ...item,
        [k]: v,
      }
    }, itemData as NetSuiteItem<T>)

  return simplified
}
