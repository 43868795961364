<template lang="pug">
v-dialog(v-model='isProcessing' max-width='320' persistent)
  v-card
    //- v-card-title(v-show='status.title') {{ status.title }}
    v-card-text.d-flex.justify-center.pa-4
      v-progress-linear(color='primary' indeterminate)
</template>

<script lang="ts">
export default {
  name: 'ProcessingHud',
}
</script>

<script lang="ts" setup>
const { isProcessing } = storeToRefs(useGlobalStore())
</script>
