/**
 * Safe Axios Handlers
 *
 * Decorates the standard Axios methods with a safe handler that will catch errors
 * and return them as a result to make it easier to handle errors in the client
 *
 * If the request is successful, `error` will be undefined and `data` will be populated
 * If the request fails, `error` will be populated and `data` will be null
 */

import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import type {
  AxiosClientResult,
  AxiosErrorResult,
  AxiosSuccessResult,
  BrilliantGenericError,
} from './types'

/**
 * Standard Axios Instance with safe handlers added
 */
export interface SafeAxiosInstance extends AxiosInstance {
  safeGet: SafeAxiosHandler
  safeHead: SafeAxiosHandler
  safeOptions: SafeAxiosHandler
  safePost: SafeAxiosHandlerWithData
  safePut: SafeAxiosHandlerWithData
  safePatch: SafeAxiosHandlerWithData
  safeDelete: SafeAxiosHandler
}

type AxiosHandler = <
  T = any,
  R extends AxiosResponse = AxiosResponse<T>,
  D = any
>(
  url: string,
  config?: AxiosRequestConfig<D>
) => Promise<R>

/**
 * A convenience wrapper that will catch errors and return them as a result
 * This only handles AxiosErrors and will rethrow any other errors
 *
 * If the request is successful, `error` will be undefined and `data` will be populated
 * If the request fails, `error` will be populated and `data` will be null
 *
 * Adds the `E` type parameter to allow for typing the response data returned by an error
 */
type SafeAxiosHandler = <
  T = any,
  E extends Record<string, unknown> = BrilliantGenericError,
  R extends AxiosSuccessResult<unknown> = AxiosSuccessResult<T>,
  D = any
>(
  url: string,
  config?: AxiosRequestConfig<D>
) => Promise<AxiosClientResult<R, E>>

export const catchAndReturnMethods = (fn: AxiosHandler): SafeAxiosHandler => {
  return async <
    T = any,
    E extends Record<string, unknown> = BrilliantGenericError,
    R extends AxiosSuccessResult<unknown> = AxiosSuccessResult<T>,
    D = any
  >(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosClientResult<R, E>> => {
    try {
      const { data, ...response } = await fn<T, AxiosResponse<T, D>, D>(
        url,
        config
      )
      return {
        response,
        data,
        error: null,
      } as R
    } catch (e) {
      if (axios.isAxiosError<E, D>(e))
        return {
          response: e.response,
          data: null,
          error: e,
        } as AxiosErrorResult<E, typeof e>

      throw e
    }
  }
}

/**
 * With Data functions are used for POST, PUT, and PATCH requests
 * They are otherwise identical to the standard functions
 */

type AxiosHandlerWithData = <
  T = any,
  R extends AxiosResponse = AxiosResponse<T>,
  D = any
>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => Promise<R>

/** Doc */
type SafeAxiosHandlerWithData = <
  T = any,
  E extends Record<string, unknown> = BrilliantGenericError,
  R extends AxiosSuccessResult<unknown> = AxiosSuccessResult<T>,
  D = any
>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig<D>
) => Promise<AxiosClientResult<R, E>>

export const catchAndReturnDataMethods = (
  fn: AxiosHandlerWithData
): SafeAxiosHandlerWithData => {
  return async <
    T = any,
    E extends Record<string, unknown> = BrilliantGenericError,
    R extends AxiosSuccessResult<unknown> = AxiosSuccessResult<T>,
    D = any
  >(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<AxiosClientResult<R, E>> => {
    try {
      const { data: _data, ...response } = await fn<T, AxiosResponse<T, D>, D>(
        url,
        data,
        config
      )
      return {
        response,
        data: _data,
        error: null,
      } as R
    } catch (e) {
      if (axios.isAxiosError<E, D>(e))
        return {
          response: e.response,
          data: null,
          error: e,
        } as AxiosErrorResult<E, typeof e>

      throw e
    }
  }
}
