import { parse } from 'csv-parse/sync'

import type { ValidatedRow } from '@/composables/useCsvValidation'

// Grouping these options together to make it clear they are bound together
const removeEmptyColumnsOptions = {
  columns: (headers: string[]) => {
    return (
      headers
        .filter((column: string) => column !== '')
        // Normalize the column names to match the data model
        .map((column: string) => column.toUpperCase())
    )
  },
  relaxColumnCountMore: true,
}

const cast = (value: string) => {
  // Converts unicode ligatures/symbols to their closest ASCII equivalent
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize#compatibility_normalization
  value = value.normalize('NFKC')

  // Cast boolean values
  // TODO: revisit during https://diamondfoundry.atlassian.net/browse/BRILL-217
  // if (value.toLocaleLowerCase() === 'true') return true
  // if (value.toLocaleLowerCase() === 'false') return false

  return value
}

export function csvToJson(data: string): Record<string, any>[] {
  return parse(data, {
    ...removeEmptyColumnsOptions,
    skipEmptyLines: true,
    cast,
    trim: true,
  })
}

export enum injectedColumnsKeys {
  invalidValues = 'invalidValues',
  lineId = 'lineId',
  message = 'message',
  nsError = 'nsError',
}

export const injectedColumns: { [key: string]: any } = {
  [injectedColumnsKeys.nsError]: {
    header: {
      title: 'MESSAGE (GENERATED)',
      headerProps: { class: 'd-none' },
      sortable: false,
    },
    value: (data: ValidatedRow) => {
      return { value: null, classes: ['d-none'] }
    },
  },
  [injectedColumnsKeys.message]: {
    header: {
      title: 'MESSAGE (GENERATED)',
      headerProps: { class: 'd-none' },
      sortable: false,
    },
    value: (data: ValidatedRow) => {
      return { value: '', classes: ['d-none'] }
    },
  },
  [injectedColumnsKeys.lineId]: {
    header: {
      title: `ID (GENERATED)`,
      // class: this.isProduction ? 'd-none' : 'text--disabled',
      headerProps: { class: 'd-none' },
      sortable: false,
    },
    value: (data: ValidatedRow, index: number) => {
      return {
        // Adjust to mirror the line number as viewed in excel
        // +2 to account for the header row and the 0-index
        value: index + 2,
        classes: ['d-none'],
      }
    },
  },
  [injectedColumnsKeys.invalidValues]: {
    header: {
      title: 'INVALID VALUES (GENERATED)',
      headerProps: { class: 'd-none' },
      sortable: false,
    },
    value: (data: ValidatedRow) => {
      return {
        value: Object.keys(data)
          .filter(key => data[key].isValid === false) // Explicitly checking if false, since it could be undefined, but that doesn't mean it's invalid
          .join(', '),
        classes: ['d-none'],
      }
    },
  },
}
