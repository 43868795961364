import type { ThemeDefinition } from 'vuetify'
import vuetifyColors from 'vuetify/lib/util/colors.mjs'

type vuetifyColorType = {
  base: string
  lighten5: string
  lighten4: string
  lighten3: string
  lighten2: string
  lighten1: string
  darken1: string
  darken2: string
  darken3: string
  darken4: string
}

const colors = {
  ...vuetifyColors,
  ...vuetifyColors,
  red: '#a32322',
  black: vuetifyColors.shades.black,
  grey: vuetifyColors.grey.darken3,
  white: vuetifyColors.shades.white,
}

/**
 * Normalizes colors to use the base color if a shade is not provided
 *
 * This is to emulate the behavior of Vuetify 2's themes. It makes it possible
 * to use a specific shade of a color in the theme, but fall back to the base
 * color if a shade is not provided.
 */
const normalizeColors = (colors: Record<string, string | vuetifyColorType>) =>
  Object.fromEntries(
    Object.entries(colors).map(([key, value]) => {
      if (typeof value !== 'string') return [key, value.base]

      return [key, value]
    })
  )

const commonColors = {
  trace: colors.blueGrey.lighten1,
  debug: colors.blueGrey.lighten1,
  info: colors.blue,
  success: colors.green,
  warn: colors.orange,
  fatal: colors.purple,
}

const themes: Record<string, ThemeDefinition> = {
  light: {
    dark: false,
    colors: normalizeColors({
      ...commonColors,
      primary: colors.black,
      secondary: colors.grey,
    }),
  },
  dark: {
    dark: true,
    colors: normalizeColors({
      ...commonColors,
      primary: colors.deepPurple.lighten1,
      fatal: colors.purple.accent3,
    }),
  },
}

export { themes, colors }
