// https://stackoverflow.com/a/21682946
const stringToColor = (string: string, saturation = 85, lightness = 50) => {
  let hash = 0
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`
}

const formatTimeDiff = (start: number, end: number) => {
  const diff = end - start
  // https://github.com/Download/ulog/blob/d52630b4af31654e96e79d65d059b9d3facfefc1/mods/formats/perf.js#L10
  return diff >= 36000000
    ? (diff / 3600000).toFixed(1) + 'h'
    : diff >= 600000
      ? (diff / 60000).toFixed(diff >= 6000000 ? 1 : 2) + 'm'
      : diff >= 10000
        ? (diff / 1000).toFixed(diff >= 100000 ? 1 : 2) + 's'
        : diff > 1
          ? diff + 'ms'
          : ''
}

const formatTime = (time: Date) => {
  const hours = time.getHours().toString().padStart(2, '0')
  const minutes = time.getMinutes().toString().padStart(2, '0')
  const seconds = time.getSeconds().toString().padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

console.log(
  'logging',
  import.meta.env.DEV || import.meta.vitest ? 'enabled' : 'disabled'
)

/**
 * Provides a simple way to neatly log messages to the console.
 * @param module the module the logger represents
 * @param name the name of the logger, used to distinguish between multiple loggers
 */
export const useLogger = (module: string, name?: string) => {
  let lastCalled = Date.now()
  const _handler = (loggerFn: typeof console.log, ...args: unknown[]) => {
    const now = Date.now()
    const timeDiff = formatTimeDiff(lastCalled, now)
    const time = formatTime(new Date(now))

    // NOTE: The console will stop formatting messages that aren't concated into
    // the first argument. This means we have to append `%s` to the end of each
    // portion of the message, even if it doesn't require formatting.
    // ex. console.log('%cred%s', 'color:red', '%cblue%s', 'color:blue', '%cgreen', 'color:green')
    const logStack: any[] = [
      `${time} %s`,
      `${timeDiff.padStart(6)} %s`,
      `[${module}] %s`,
    ]
    if (name)
      logStack.push(
        `[%c${name}%c] %s`,
        `color: ${stringToColor(name)}`,
        'color: unset'
      )

    if (args.length) logStack.push(...args)

    loggerFn.call(null, ...logStack)
    lastCalled = Date.now()
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const _log = import.meta.env.DEV || import.meta.vitest ? _handler : () => {}

  const debug: typeof console.debug = (...args: unknown[]) =>
    _log(console.debug, ...args)
  const log: typeof console.log = (...args: unknown[]) =>
    _log(console.log, ...args)
  const warn: typeof console.warn = (...args: unknown[]) =>
    _log(console.warn, ...args)
  const error: typeof console.error = (...args: unknown[]) =>
    _log(console.error, ...args)

  const group: typeof console.group = (...args: unknown[]) => {
    if (import.meta.env.MODE === 'development') {
      console.group(...args)
    }
  }
  const groupEnd: typeof console.groupEnd = () => {
    if (import.meta.env.MODE === 'development') {
      console.groupEnd()
    }
  }

  return {
    debug,
    log,
    warn,
    error,
    group,
    groupEnd,
  }
}
