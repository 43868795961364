// TODO: Refactor to act as a store, adding whatever is passed to it, and eliminating the need for a type
import type { Ref } from 'vue'

export type PreferenceTypes = {
  useDarkMode: boolean
  useMiniSidebar: boolean
  kafkaPollingInterval: number
}
const defaults: PreferenceTypes = {
  useDarkMode: import.meta.env.MODE === 'development',
  useMiniSidebar: false,
  kafkaPollingInterval: 15_000,
}

export type UserPreferences = {
  [key in keyof PreferenceTypes]: Ref<PreferenceTypes[key]>
}

let preferences: UserPreferences | null = null

/**
 * A composable that provides access to user preferences stored in local storage.
 *
 * @param prefix The prefix to use for the local storage keys. Defaults to `brilliant.pref.`
 * @returns An singleton containing references to the user preferences
 *
 * @example
 * const { useDarkMode, useMiniSidebar } = usePreferences()
 */
export const usePreferences = (prefix = 'brilliant.pref.') => {
  if (preferences == null)
    preferences = Object.fromEntries(
      Object.entries(defaults).map(([key, defaultVal]) => [
        key,
        useLocalStorage(`${prefix}${key}`, defaultVal),
      ])
    ) as UserPreferences
  return preferences
}
