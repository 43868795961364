import { createAuthGuard } from '@auth0/auth0-vue'
import type { App } from 'vue'
import {
  type RouteRecordRaw,
  createWebHistory,
  createRouter as _createRouter,
} from 'vue-router'

import Dashboard from '../views/Dashboard.vue'
import ParentRouterView from '../views/ParentRouterView.vue'

import { roleGroups } from '@/auth/roles'
import type { Role } from '@/auth/types'

declare module 'vue-router' {
  interface RouteMeta {
    roleGroup?: Role | Role[]
    notAuthedRedirect?: RouteLocationRaw
  }
}

export const routes: RouteRecordRaw[] = [
  // Redirect to dashboard to make the active route cleaner
  // This also frees up the route to be used for something else in the
  // future if we want to.
  {
    path: '/',
    redirect: 'dashboard',
  },

  // Dashboard
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
    },
  },

  // BullBoard
  // This route only exists to notify the user if the reverse proxy is not configured
  {
    path: '/bullmq-ui',
    name: 'bullmq-ui',
    component: () => import('../views/MissingReverseProxy.vue'),
  },
  // The `:all*` param is used to capture the entire path after the route
  // making it possible to load the BullBoard UI with the correct path
  {
    path: '/bullboard/:all*',
    name: 'bullboard',
    component: () => import('../views/BullBoard.vue'),
    props: true,
    meta: {
      title: 'Bull Board',
    },
  },

  // Activity
  {
    path: '/activity',
    name: 'activity.index',
    meta: {
      title: 'Activity',
    },
    component: () => import('../views/activity/Index.vue'),
    children: [
      {
        path: 'order-error/:id',
        name: 'order-error.landing',
        props: true,
        meta: {
          title: 'Order Error',
        },
        component: () => import('../views/activity/OrderDetails.vue'),
      },
      {
        path: 'order-details/:id',
        name: 'order-details.landing',
        props: true,
        meta: {
          title: 'Order Details',
        },
        component: () => import('../views/activity/OrderDetails.vue'),
      },
    ],
  },

  // BullBoard
  // This route only exists to notify the user if the reverse proxy is not configured
  {
    path: '/bullmq-ui',
    name: 'bullmq-ui',
    component: () => import('../views/MissingReverseProxy.vue'),
  },
  // The `:all*` param is used to capture the entire path after the route
  // making it possible to load the BullBoard UI with the correct path
  {
    path: '/bullboard/:all*',
    name: 'bullboard',
    component: () => import('../views/BullBoard.vue'),
    props: true,
    meta: {
      title: 'Bull Board',
    },
  },

  // Gem Feedback
  {
    path: '/gem-feedback',
    name: 'gem-feedback.index',
    meta: {
      title: 'Gem Feedback',
      roleGroup: roleGroups.gemFeedback,
    },
    component: () => import('../views/gem-feedback/Index.vue'),
  },

  // Kafka Subscription Management
  {
    path: '/kafka-subscriptions',
    name: 'kafka-subscriptions.index',
    meta: {
      title: 'Kafka Subscription Management',
      roleGroup: roleGroups.admin,
    },
    component: () => import('../views/kafka-subscriptions/Index.vue'),
  },

  // UKG Audit
  {
    path: '/ukg-sync',
    name: 'ukg-sync.index',
    meta: {
      title: 'UKG Audit',
      roleGroup: roleGroups.hr,
    },
    component: () => import('../views/UKGSync.vue'),
  },

  // Gem Feedback
  {
    path: '/gem-feedback',
    name: 'gem-feedback.index',
    meta: {
      title: 'Gem Feedback',
      roleGroup: roleGroups.gemFeedback,
    },
    component: () => import('../views/gem-feedback/Index.vue'),
  },

  // Polishing
  {
    path: '/polishing',
    component: ParentRouterView,
    meta: {
      title: 'Polishing',
      roleGroup: roleGroups.polishing,
    },
    children: [
      {
        path: '',
        name: 'polishing',
        component: () => import('../views/polishing/Index.vue'),
        meta: {
          roleGroup: roleGroups.polishing,
        },
      },
      {
        path: 'upload',
        name: 'polishing.upload',
        component: () => import('../views/polishing/Upload.vue'),
        meta: {
          title: 'CSV Upload',
          roleGroup: roleGroups.polishing,
        },
      },
      {
        path: 'stone/:gemId',
        name: 'polishing.details',
        props: route => ({
          gemId: route.params.gemId,
        }),
        component: () => import('../views/polishing/Details.vue'),
        meta: {
          title: 'Finished Stone Details',
          roleGroup: roleGroups.polishing,
        },
      },
    ],
  },

  // Sorting
  {
    path: '/sorting',
    component: ParentRouterView,
    meta: {
      title: 'Sorting',
      roleGroup: roleGroups.sorting,
    },
    children: [
      {
        path: '',
        name: 'sorting',
        component: () => import('../views/sorting/Index.vue'),
        meta: {
          roleGroup: roleGroups.sorting,
        },
      },
      {
        path: 'upload',
        name: 'sorting.upload',
        component: () => import('../views/sorting/Upload.vue'),
        meta: {
          title: 'CSV Upload',
          roleGroup: roleGroups.sorting,
        },
      },
      {
        path: 'jar/:jarBarcodeId',
        name: 'sorting.details',
        props: route => ({
          jarBarcodeId: route.params.jarBarcodeId,
        }),
        component: () => import('../views/sorting/Details.vue'),
        meta: {
          title: 'Jar Details',
          roleGroup: roleGroups.sorting,
        },
      },
    ],
  },
]

export const createRouter = (app: App) => {
  const router = _createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
  })

  // Check authentication before each route
  router.beforeEach(createAuthGuard(app))

  return router
}
