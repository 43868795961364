<template lang="pug">
widget-base(
  title='Polishing',
  :button-props='buttonProps',
  :refresh='refresh',
  :error='error',
  :has-data='summaryItems?.length > 0',
  :loading='loading'
)
  v-list(v-if='summaryItems' dense)
    template(v-for='(item, index) in summaryItems', :key='item.text')
      v-list-item(:to='item.to')
        v-row(no-gutters align='center')
          v-col.text-center.mr-2(cols='auto' style='width: 65px')
            v-chip(:color='item.color') {{ item.value }}
          v-col.text-body-1 {{ item.text }}

      // Show divider in between each item
      v-divider(:key='index' v-if='index < summaryItems?.length - 1')
</template>

<script lang="ts">
export default {
  name: 'WidgetPolishing',
}
</script>

<script setup lang="ts">
import { fetchStoneSummary, stonesHeadersMapping } from '@/api/polishing'

const buttonProps = {
  prependIcon: 'mdi-upload',
  to: { name: 'polishing.upload' },
  text: 'Upload CSV',
}

interface StatusItem {
  text: string
  value: number
  color: string
  to: { name: string; query?: { [key: string]: string | number | boolean } }
}

const { status } = stonesHeadersMapping
const statusKey = status.key

/**
 * Map individual statuses to a search group. Not as necessary as the sorting
 * widget, but this will make changes easier to make if these change.
 * Grouping definitions: https://github.com/diamond-foundry/netsuite-account-customization/blob/main/src/FileCabinet/SuiteScripts/df/diamond-lifecycle/modules/df-diamond-lifecycle-search.js#L212
 *
 * Status Enum/Definitions
 * https://github.com/diamond-foundry/netsuite-account-customization-sandbox-1/blob/main/src/FileCabinet/SuiteScripts/df/utils/df-id.js#L541
 */
const statusGroupMap = {
  pending: 'pending',
  'in progress': 'in progress',
  error: 'error',
  complete: 'complete',
}
type StatusGroupKey = keyof typeof statusGroupMap

const getColor = (status: StatusGroupKey) => {
  switch (status.toLocaleLowerCase()) {
    case 'error':
      return 'error'
    case 'complete':
      return 'success'
    case 'pending':
    case 'in progress':
      return 'info'
    default:
      return 'grey lighten-1'
  }
}

const { loading, data, error, refresh } =
  useSafeComponentStore(fetchStoneSummary)
const summaryItems = computed(() => {
  if (!data.value) return []
  const items = data.value.data.map(({ text: status, total: count }) => {
    const lowerStatus = status.toLowerCase() as StatusGroupKey

    const statusGroup = statusGroupMap[lowerStatus]

    return {
      text: status,
      value: Number(count),
      color: getColor(lowerStatus),
      to: {
        name: `polishing`,
        query: { [statusKey]: statusGroup },
      },
    } satisfies StatusItem
  })

  return items
})

onMounted(() => {
  refresh()
})
</script>
