<template lang="pug">
widget-base(
  :title='name',
  :refresh='refresh',
  :error='error',
  :has-data='items.length > 0',
  :loading='loading'
)
  template(#title)
    span.text-no-wrap {{ name }}
    v-chip.ml-3(v-if='data' color='primary' density='comfortable' size='small') {{ data.total }} total

  v-list(v-if='items.length > 0')
    template(v-for='(item, index) in items', :key='item.id')
      v-list-item(:to='item.to')
        v-row(no-gutters align='center')
          v-col.text-center.mr-2(cols='auto' style='width: 65px')
            v-chip(:color='item.color') {{ item.count }}
          v-col.text-body-1 {{ item.text }}

      // Show divider in between each item
      v-divider(:key='index' v-if='index < items.length - 1')
</template>

<script lang="ts" setup>
import type { RouteLocationNamedRaw } from 'vue-router'

import type {
  AxiosClientResult,
  BrilliantSearchParams,
  BrilliantSummaryResult,
} from '@/api/types'

export type Props = {
  name: string
  // Using RouteLocationNamedRaw instead of RouteLocationRaw because we need the name
  route: RouteLocationNamedRaw
  summaryFn: (
    params?: BrilliantSearchParams
  ) => Promise<AxiosClientResult<BrilliantSummaryResult>>
  summaryMap: Record<string, { color: string; filter: number; label: string }>
}

const props = defineProps<Props>()

const { loading, data, error, refresh } = useSafeComponentStore(props.summaryFn)
const items = computed(() => {
  if (!data.value || !data.value) return []
  return (
    Object.entries(data.value.statuses)
      .map(([status, { count, name }]) =>
        props.summaryMap[status]
          ? {
              id: status,
              count,
              color: props.summaryMap[status].color,
              text: name,
              to: {
                name: props.route.name,
                query: {
                  ...props.route.query,
                  'filter-by': props.summaryMap[status].filter,
                },
              },
            }
          : // By using an empty object typescript doesn't believe null is a possible value
            {}
      )
      // Remove the skipped items
      .filter(item => item.id)
  )
})
refresh()
</script>
