import { roleGroups } from '@/auth/roles'
import { Role, claimNamespace } from '@/auth/types'

export const useAuth = () => {
  const auth0 = useAuth0()

  const user = auth0.user
  const claims = computed(() => user.value?.[claimNamespace] ?? {})
  const roles = computed(() => claims.value.roles ?? [])

  /**
   * Checks if the user has a Role on their Auth0 account.
   * @param role The role to check for.
   * @returns Null if the role itself doesn't exist, otherwise true if the user
   * has the role, false if they don't.
   */
  const hasRole = (role: Role) => {
    if (Role[role] === undefined) return null
    return roles.value.includes(role)
  }

  /**
   * Checks if the user has any of the specified roles on their Auth0 account.
   * If no roles are specified, returns true.
   */
  const hasAccess = (roleGroup?: Role | Role[]) => {
    if (roleGroup === undefined) return true
    return Array.isArray(roleGroup)
      ? roleGroup.some(role => hasRole(role))
      : !!hasRole(roleGroup)
  }

  return {
    auth0,

    user,
    claims,
    roles,

    hasRole,
    hasAccess,

    Role,
    roleGroups,
  }
}
