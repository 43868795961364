export type SnackbarState = {
  message: string
  color: string
  timeout: number
  action?: {
    label: string
    color?: string
    handler: () => void
  }
}

const DEFAULT_TIMEOUT = 5000
const ERROR_TIMEOUT = 10000

const snacks = ref<SnackbarState[]>([])

const currentSnack = computed(() => snacks.value[0])
const snacksLeft = computed(() => snacks.value.length - 1)

/** The state the component uses to know if it should open or close */
const _active = ref(false)

/**
 * Changes state when the snackbar is either fully open or fully closed
 * i.e. it will lag behind `_active` by the duration of the animation
 *
 * Specifically useful for tracking when another snack can be displayed
 */
const _closed = ref(true)
whenever(_closed, () => _resolveSnack())

/**
 * Adds a snack to the queue, displays it if there are no other snacks
 *
 * @param snack The snack to add to the queue
 * @param inject Whether or not to insert the snack as the next in queue
 */
const _queueSnack = (snack: SnackbarState, inject = false) => {
  if (_closed.value && !_active.value) _active.value = true
  if (inject) snacks.value.splice(1, 0, snack)
  else snacks.value.push(snack)
}

/**
 * Removes the current snack from the queue and displays the next one
 */
const _resolveSnack = () => {
  if (snacksLeft.value > 0) {
    _active.value = true
  }

  snacks.value.shift()
}

/**
 * Displays a snack with the given message or appends it to the queue
 *
 * @example
 * triggerSnack('Hello world!')
 * triggerSnack('OH NO!', { color: 'error' })
 */
const triggerSnack = (message: string, options?: Partial<SnackbarState>) =>
  _queueSnack({
    message,
    color: 'primary',
    timeout: DEFAULT_TIMEOUT,
    ...options,
  })

/**
 * Displays an error snack with the given message or appends it to the queue
 *
 * @example
 * triggerErrorSnack('OH NO!')
 */
const triggerErrorSnack = (message: string, options?: Partial<SnackbarState>) =>
  _queueSnack({
    message,
    color: 'error',
    timeout: ERROR_TIMEOUT,
    ...options,
  })

/**
 * Displays a snack with the given message or inserts it as the next snack in line
 *
 * @example
 * triggerSnack('Hello world!', {
 *   action: {
 *     label: 'Undo',
 *     handler: () => { injectSnack('Operation undone!') }
 *   }
 * })
 */
const injectSnack = (message: string, options?: Partial<SnackbarState>) =>
  _queueSnack(
    {
      message,
      color: 'success',
      timeout: DEFAULT_TIMEOUT,
      ...options,
    },
    true
  )

/**
 * Closes the current snack
 */
const closeSnack = () => {
  _active.value = false
}

/**
 * Emits events to the snackbar component
 * Primarily used by the snackbar component itself
 */
export const useSnackbarEvents = () => {
  const emitSnackOpened = () => {
    _closed.value = false
  }

  const emitSnackClosed = () => {
    _closed.value = true
  }

  return {
    emitSnackClosed,
    emitSnackOpened,
  }
}

export const useSnackbar = () => {
  return {
    _active: _active,
    _closed: readonly(_closed),
    snacks: readonly(snacks),
    snacksLeft,
    currentSnack,
    triggerSnack,
    triggerErrorSnack,
    injectSnack,
    closeSnack,
  }
}
