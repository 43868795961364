import type { AxiosRequestConfig } from 'axios'

import { createClient } from './client'
import { fetchNetSuiteSummary } from './generic-crud'
import type {
  NetSuiteSummaryData,
  NetSuiteFetchResponse,
  NetSuiteResponse,
  NetSuiteSearchResponse,
} from './types'

import type { DataModel } from '@/composables/useCsvValidation'
import type { RawNetSuiteItem, NetSuiteItemRecord, NetSuiteItem } from '@/types'
import { serializeFieldsAsJSON } from '@/utils'
import { transformRawNetSuiteItem } from '@/utils/transformers/generic'
import type { DataTableHeader } from 'vuetify/components/VDataTable'

const sortingRoute = '/sorting'
const jarsRoute = '/jars'

export enum JAR_STATUS_GROUPS {
  INVENTORY = 'inventory',
  SORTING = 'sorting',
  BUILDING = 'building',
  COMPLETE = 'complete',
}

export interface JarSearchParams {
  pageIndex?: number
  pageSize?: number
  sortColumn?: string
  sortDirection?: 'ASC' | 'DESC'
  id?: string
  jarBarcode?: string
  keyword?: string
  statusGroups?: JAR_STATUS_GROUPS[]
  resultColumns?: string[]
}

export interface SortingPayloadItem<T = any> {
  jar_barcode: string
  build: boolean
  blocks: T[]
}

export type SortingDataModel = DataModel

export interface SortingCreationResponse {
  status: 'success'
}

export type JarGradedData = {
  jar_barcode: string
  build: boolean
  blocks: JarBlock[]
}

export type RawJarData = {
  internalid: NetSuiteItemRecord[]
  type: NetSuiteItemRecord[]
  itemid: string
  quantityavailable: number
  quantitybackordered: number
  quantityonhand: number
  totalValue: number
  custitem_df_rough_jar_id: string
  custitem_df_rough_jar_barcode: string
  custitem_df_rough_jar_complete_date: string
  custitem_df_rough_lifecycle_status: NetSuiteItemRecord[]
  custitem_df_rough_production_name: string
  custitem_df_graded_data_json_blob: JarGradedData
}
export type RawNetSuiteJar = RawNetSuiteItem<RawJarData>
export type RawJar = NetSuiteItem<RawJarData>

// expands object types one level deep
type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never

// expands object types recursively
type ExpandRecursively<T> = T extends object
  ? T extends infer O
    ? { [K in keyof O]: ExpandRecursively<O[K]> }
    : never
  : T

type ExpandedRawJarData = ExpandRecursively<RawJar>

// Future shape of the jar after cleaning up prefixes and what not
// export interface Jar {
//   id: string
//   itemid: string
//   recordType: string
//   jarBarcode: string
//   jarId: string
//   jarLifecycleStatus: NetSuiteItemRecord
//   productionName: string
//   quantityAvailable: number
//   quantityBackordered: number
//   quantityOnHand: number
//   totalValue: number
//   type: NetSuiteItemRecord
//   blocks: JarBlock[]
// }
// type JarItem = NetSuiteItem<Jar>

export interface JarBlock {
  [key: string]: string | number | undefined
  guid: string
  jar_barcode: string
  quality: string
  carats: number
  caratsConsumed?: number
  color?: string
  size?: string
  error?: string
}

export const jarsHeadersMapping = {
  type: {
    title: 'Type',
    key: 'type',
  },
  id: {
    title: 'NetSuite Internal ID',
    key: 'internalid',
  },
  itemId: {
    title: 'Name',
    key: 'itemid',
  },
  quantityAvailable: {
    title: 'Quantity Available',
    key: 'quantityavailable',
  },
  quantityBackordered: {
    title: 'Quantity Backordered',
    key: 'quantitybackordered',
  },
  quantityOnHand: {
    title: 'Quantity On Hand',
    key: 'quantityonhand',
  },
  totalValue: {
    title: 'Total Value',
    key: 'totalvalue',
  },
  jarId: {
    title: 'Jar ID',
    key: 'custitem_df_rough_jar_id',
  },
  jarBarcode: {
    title: 'Jar Barcode',
    key: 'custitem_df_rough_jar_barcode',
  },
  jarCompleteDate: {
    title: 'Complete Date',
    key: 'custitem_df_rough_jar_complete_date',
  },
  jarLifecycleStatus: {
    title: 'Lifecycle Status',
    key: 'custitem_df_rough_lifecycle_status',
  },
  roughProductionName: {
    title: 'Rough Production Name',
    key: 'custitem_df_rough_production_name',
  },
  gradedDataJsonBlob: {
    title: 'Graded Data Blob',
    key: 'custitem_df_graded_data_json_blob',
  },
} as const satisfies Record<string, DataTableHeader>

/**
 * Columns to request NetSuite return when fetching stones
 */
export const jarsColumns = Object.values(jarsHeadersMapping).map(
  (map: DataTableHeader) => map.key
)

export const defaultStatusGroups = [
  // JAR_STATUS_GROUPS.INVENTORY,
  JAR_STATUS_GROUPS.SORTING,
  JAR_STATUS_GROUPS.BUILDING,
  JAR_STATUS_GROUPS.COMPLETE,
]

/**
 * SORTING
 */

const _fetchSummary = fetchNetSuiteSummary<NetSuiteSummaryData>
export const fetchJarSummary = _fetchSummary.bind(null, jarsRoute)

/**
 * Load the firmly defined fields for the sorting data
 * @returns The data model for the sorting data
 */
export async function fetchSortingDataModel() {
  return (await createClient()).get<SortingDataModel>(
    `${sortingRoute}/data-model`
  )
}

/**
 * Create the actual sorting object
 */
export async function createSorting(sortingData: SortingPayloadItem<JarBlock>) {
  return (await createClient()).post<SortingCreationResponse>(
    sortingRoute,
    sortingData
  )
}

/**
 * Fetch jars from Netsuite
 */
export async function fetchJars(
  params: JarSearchParams,
  config: AxiosRequestConfig = {}
) {
  // Serialize specific fields
  // https://github.com/diamond-foundry/netsuite-account-customization-sandbox-1/blob/97955b6cb4f355ece0c0299e8e3a3ce8890d956b/src/FileCabinet/SuiteScripts/df/diamond-lifecycle/modules/df-diamond-lifecycle-search.js#L53
  config.params = serializeFieldsAsJSON(params, [
    'statusGroups',
    'resultColumns',
  ])
  const { data: nsResponse, ...response } = await (
    await createClient()
  ).get<NetSuiteSearchResponse<RawNetSuiteJar>>(jarsRoute, config)

  const jars = nsResponse.data.items.map(transformRawNetSuiteItem)

  return {
    response,
    pagination: nsResponse.data.info,
    data: jars,
  } as NetSuiteFetchResponse<RawJar[]>
}

export async function retryBuild(internalId: string) {
  const client = await createClient()
  const result = await client.safePost<
    NetSuiteResponse<{ jar_internal_id: number }>
  >(`${jarsRoute}/retry-build`, {
    jar_internal_id: internalId,
  })

  if (result.error) return result
  const nsResponse = result.data

  return {
    ...result,
    data: nsResponse.data,
  }
}

export async function unbuildJar(internalId: string) {
  const client = await createClient()
  const result = await client.safePost<
    NetSuiteResponse<{
      workOrderIDs: number[]
      assemblyBuildIDs: number[]
    }>
  >(`${jarsRoute}/unbuild`, {
    jar_internal_id: internalId,
  })

  if (result.error) return result
  const nsResponse = result.data

  return {
    ...result,
    data: nsResponse.data,
  }
}
