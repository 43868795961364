import type { AxiosRequestConfig } from 'axios'

import { createClient } from './client'
import { createResource } from './generic-crud'
import type {
  BullJobList,
  BullJobListFetchFn,
  BullJobListFetchParams,
  BullJobListItem,
  BrilliantResponseMeta,
} from './types'

const baseRoute = '/queues/gem-feedback/upload'
// const baseRoute = '/queues/noop'

export interface GemFeedbackMetadata {
  filename?: string
  uploaded_by?: string
}

//
export type GemFeedbackData = Record<string, any>

export interface GemFeedbackJobData {
  metadata: GemFeedbackMetadata
  data: GemFeedbackData
}

export type GemFeedbackItem = BullJobListItem<GemFeedbackJobData>
export type GemFeedbackList = BullJobList<GemFeedbackJobData>

export type GemFeedbackCreatePayload = GemFeedbackJobData

export type GemFeedbackListResponse = {
  meta: BrilliantResponseMeta
  data: GemFeedbackList
}

export const AllBullMQStatusTypes = [
  'completed',
  'failed',

  'active',
  'delayed',
  'waiting',
  'waiting-children',

  'paused',
  'repeat',
  'wait',
]

/**
 * Gem Feedback API
 */

//#region -- Fetch Upload Jobs

// import gemFeedbackMock from './kafka-subscriptions.mock'
// const _fetchAll = () => {
//   return Promise.resolve({
//     data: gemFeedbackMock as GemFeedback[],
//   })
// }
/**
 * Fetch all upload jobs
 * @example
 * const uploadJobs = await fetchGemFeedbackUploads()
 */
export const fetchGemFeedbackUploads: BullJobListFetchFn<
  GemFeedbackJobData
> = async (
  params?: BullJobListFetchParams,
  config: AxiosRequestConfig = {}
) => {
  // Default to requesting all statuses
  config.params = { status: AllBullMQStatusTypes, ...params }
  const client = await createClient()
  const result = await client.safeGet<{
    meta: BrilliantResponseMeta
    data: GemFeedbackList
  }>(`${baseRoute}`, config)

  return result
}

//#endregion

//#region -- Submit Upload Job
/**
 * Queue a new upload job
 * @example
 * const { response } = await uploadGemFeedback({...})
 */
export const uploadGemFeedback = (createResource<
  GemFeedbackCreatePayload,
  GemFeedbackData
>).bind(null, baseRoute)
//#endregion
