<template lang="pug">
widget-base(
  title='Sorting',
  :button-props='buttonProps',
  :refresh='refresh',
  :error='error',
  :has-data='summaryItems?.length > 0',
  :loading='loading'
)
  v-list(v-if='summaryItems' density='comfortable')
    template(v-for='(item, index) in summaryItems', :key='item.text')
      v-list-item(:to='item.to')
        v-row(no-gutters align='center')
          v-col.text-center.mr-2(cols='auto' style='width: 65px')
            v-chip(:color='item.color') {{ item.value }}
          v-col.text-body-1 {{ item.text }}

      //- Show divider in between each item
      v-divider(:key='index' v-if='index < summaryItems.length - 1')
</template>

<script setup lang="ts">
import { fetchJarSummary, jarsHeadersMapping } from '@/api/sorting'

const buttonProps = {
  prependIcon: 'mdi-upload',
  to: { name: 'sorting.upload' },
  text: 'Upload CSV',
}

interface StatusItem {
  text: string
  value: number
  color: string
  to: { name: string; query?: { [key: string]: string | number | boolean } }
}

const { jarLifecycleStatus } = jarsHeadersMapping
const statusKey = jarLifecycleStatus.key

/**
 * Map individual statuses to a search group
 * Grouping defined here: https://github.com/diamond-foundry/netsuite-account-customization/blob/main/src/FileCabinet/SuiteScripts/df/diamond-lifecycle/modules/df-diamond-lifecycle-search.js#L91
 *
 * Status Enum/Definitions
 * https://github.com/diamond-foundry/netsuite-account-customization/blob/main/src/FileCabinet/SuiteScripts/df/utils/df-id.js#L304
 */
const statusGroupMap = {
  'inventory addition pending': 'inventory',
  'inventory processing': 'inventory',
  'inventory error': 'inventory',
  'sort pending': 'sorting',
  'sort draft': 'sorting',
  'sort data error': 'sorting',
  'build pending': 'building',
  building: 'building',
  'building error': 'building',
  complete: 'complete',
}

type StatusGroupKey = keyof typeof statusGroupMap

const getColor = (status: StatusGroupKey) => {
  switch (status) {
    case 'inventory error':
    case 'sort data error':
    case 'building error':
      return 'error'
    case 'complete':
      return 'success'
    case 'inventory addition pending':
    case 'sort pending':
    case 'build pending':
      return 'info'
    case 'building':
    case 'sort draft':
    case 'inventory processing':
      return 'grey lighten-1'
    default:
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = status
      return 'grey lighten-1'
  }
}

const { loading, data, error, refresh } = useSafeComponentStore(fetchJarSummary)
const summaryItems = computed(() => {
  if (!data.value) return []
  const items = data.value.data.map(({ text: status, total: count }) => {
    const lowerStatus = status.toLowerCase() as StatusGroupKey

    const statusGroup = statusGroupMap[lowerStatus]

    return {
      text: status,
      value: Number(count),
      color: getColor(lowerStatus),
      to: {
        name: `sorting`,
        query: { [statusKey]: statusGroup },
      },
    } satisfies StatusItem
  })

  return items
})

onMounted(() => {
  refresh()
})
</script>
