<template lang="pug">
//- Global nav
v-list(nav)
  template(v-for='navItem in navItems')
    v-divider(v-if='navItem.type == "divider"')
    v-list-subheader(v-else-if='navItem.type == "subheader"') {{ navItem.label }}
    //- Top Level Items
    v-list-item(
      :key='navItem.label'
      v-else-if='hasAccess(navItem.roleGroup)',
      :to='navItem.to',
      :prepend-icon='navItem.icon',
      :title='navItem.label'
    )
      v-tooltip(
        activator='parent'
        location='end',
        :text='navItem.label',
        :disabled='!navDrawer'
      )

  v-spacer
</template>

<script lang="ts" setup>
import type { NavItem } from '@/types'

const props = defineProps<{
  navItems: NavItem[]
  navDrawer: boolean
}>()

const { hasAccess, roleGroups } = useAuth()
</script>
