import type { AxiosRequestConfig } from 'axios'
import type { DataTableHeader } from 'vuetify/components/VDataTable'

import { createClient } from './client'
import { fetchNetSuiteSummary } from './generic-crud'
import type {
  NetSuiteFetchResponse,
  NetSuiteSearchResponse,
  NetSuiteSummaryData,
} from './types'

import type { DataModel } from '@/composables/useCsvValidation'
import type { NetSuiteItem, NetSuiteItemRecord, RawNetSuiteItem } from '@/types'
import { serializeFieldsAsJSON } from '@/utils'
import { transformRawNetSuiteItem } from '@/utils/transformers/generic'

const polishingRoute = '/polishing'
const stonesRoute = '/stones'

export interface StoneSearchParams {
  pageIndex?: number
  pageSize?: number
  sortColumn?: string
  sortDirection?: 'ASC' | 'DESC'
  gemId?: string
  keyword?: string
  status?: string[]
  resultColumns?: string[]
}

export enum STONE_STATUS {
  PENDING = 'pending',
  IN_PROGRESS = 'in progress',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export interface PolishingPayloadItem {
  stones: Array<any>
}

export type PolishingDataModel = DataModel

export interface PolishingCreationResponse {
  status: 'success'
}

export interface RawStoneData {
  id: string
  name: string
  created: string
  custrecord_fsd_status: NetSuiteItemRecord
  custrecord_fsd_assembly_item: NetSuiteItemRecord
  custrecord_fsd_assembly_build: NetSuiteItemRecord
  custrecord_fsd_group_guid: string
  custrecord_fsd_pot_sku?: NetSuiteItemRecord
  custrecord_fsd_gem_id: string
  custrecord_fsd_carats_rough_cut: string
  custrecord_fsd_carats_rough_cut_og: string
  custrecord_fsd_carats_produced: string
  custrecord_fsd_polisher_id: NetSuiteItemRecord
  custrecord_fsd_rework: boolean
  custrecord_fsd_rework_stone_id: NetSuiteItemRecord
  custrecord_fsd_rough_block_id: string
  custrecord_fsd_transfer_order_num: NetSuiteItemRecord
  custrecord_fsd_parcel_name: string
  custrecord_fsd_parcel_id: string
  custrecord_fsd_creation_date: string
  custrecord_fsd_shape: NetSuiteItemRecord
  custrecord_fsd_clarity: NetSuiteItemRecord
  custrecord_fsd_color: NetSuiteItemRecord
  custrecord_fsd_tint: NetSuiteItemRecord
  custrecord_fsd_length_mm: string
  custrecord_fsd_width_mm: string
  custrecord_fsd_depth_mm: string
  custrecord_fsd_cut: NetSuiteItemRecord
  custrecord_fsd_polish: NetSuiteItemRecord
  custrecord_fsd_symmetry: NetSuiteItemRecord
  custrecord_fsd_depth: string
  custrecord_fsd_table_diameter: string
  custrecord_fsd_crown_height: string
  custrecord_fsd_crown_angle: string
  custrecord_fsd_pavillion_height: string
  custrecord_fsd_pavillion_angle: string
  custrecord_fsd_girdle: NetSuiteItemRecord
  custrecord_fsd_program_type?: NetSuiteItemRecord
  custrecord_fsd_fluorescence: NetSuiteItemRecord
  custrecord_fsd_expedited: string
  custrecord_fsd_treatment_vendor_id?: NetSuiteItemRecord
  custrecord_fsd_treatment_order: string
}
export type RawNetSuiteStone = RawNetSuiteItem<RawStoneData>
export type RawStone = NetSuiteItem<RawStoneData>

// export interface Stone {
//   id: string
//   name: string
//   created: string
//   status: NetSuiteItemRecord
//   assemblyItem: NetSuiteItemRecord
//   build: NetSuiteItemRecord
//   groupGuid: string
//   potSku?: NetSuiteItemRecord
//   gemId: string
//   caratsRoughCut: string
//   caratsRoughCutOg: string
//   caratsProduced: string
//   polisherId: NetSuiteItemRecord
//   rework: boolean
//   reworkStoneId: NetSuiteItemRecord
//   roughBlockId: string
//   transferOrderNum: NetSuiteItemRecord
//   parcelName: string
//   parcelId: string
//   creationDate: string
//   shape: NetSuiteItemRecord
//   clarity: NetSuiteItemRecord
//   color: NetSuiteItemRecord
//   tint: NetSuiteItemRecord
//   lengthMm: string
//   widthMm: string
//   depthMm: string
//   cut: NetSuiteItemRecord
//   polish: NetSuiteItemRecord
//   symmetry: NetSuiteItemRecord
//   depth: string
//   tableDiameter: string
//   crownHeight: string
//   crownAngle: string
//   pavillionHeight: string
//   pavillionAngle: string
//   girdle: NetSuiteItemRecord
//   programType?: NetSuiteItemRecord
//   fluorescence: NetSuiteItemRecord
//   expedited: string
//   treatmentVendorId?: NetSuiteItemRecord
//   treatmentOrder: string
// }
// type StoneItem = NetSuiteItem<Stone>

/**
 * Mapping of the fields to be displayed in the table
 */
export const stonesHeadersMapping = {
  crownHeight: {
    title: 'Crown Height',
    key: 'custrecord_fsd_crown_height',
  },
  clarity: {
    title: 'Clarity',
    key: 'custrecord_fsd_clarity',
  },
  polisherId: {
    title: 'Polisher',
    key: 'custrecord_fsd_polisher_id',
  },
  assemblyItem: {
    title: 'Assembly Item',
    key: 'custrecord_fsd_assembly_item',
  },
  color: {
    title: 'Color',
    key: 'custrecord_fsd_color',
  },
  widthMm: {
    title: 'Width MM',
    key: 'custrecord_fsd_width_mm',
  },
  cut: {
    title: 'Cut',
    key: 'custrecord_fsd_cut',
  },
  gemId: {
    title: 'Gem ID',
    key: 'custrecord_fsd_gem_id',
  },
  creationDate: {
    title: 'Creation Date',
    key: 'custrecord_fsd_creation_date',
  },
  reworkStoneId: {
    title: 'Rework Stone ID',
    key: 'custrecord_fsd_rework_stone_id',
  },
  tableDiameter: {
    title: 'Table Diameter',
    key: 'custrecord_fsd_table_diameter',
  },
  crownAngle: {
    title: 'Crown Angle',
    key: 'custrecord_fsd_crown_angle',
  },
  pavillionAngle: {
    title: 'Pavillion Angle',
    key: 'custrecord_fsd_pavillion_angle',
  },
  polish: {
    title: 'Polish',
    key: 'custrecord_fsd_polish',
  },
  shape: {
    title: 'Shape',
    key: 'custrecord_fsd_shape',
  },
  treatmentVendorId: {
    title: 'Treatment Vendor',
    key: 'custrecord_fsd_treatment_vendor_id',
  },
  caratsRoughCut: {
    title: 'Carats Rough Cut',
    key: 'custrecord_fsd_carats_rough_cut',
  },
  pavillionHeight: {
    title: 'Pavillion Height',
    key: 'custrecord_fsd_pavillion_height',
  },
  user: {
    title: 'User',
    key: 'custrecord_fsd_user',
  },
  depth: {
    title: 'Depth',
    key: 'custrecord_fsd_depth',
  },
  rework: {
    title: 'Rework',
    key: 'custrecord_fsd_rework',
  },
  symmetry: {
    title: 'Symmetry',
    key: 'custrecord_fsd_symmetry',
  },
  groupGuid: {
    title: 'Group ID',
    key: 'custrecord_fsd_group_guid',
  },
  depthMm: {
    title: 'Depth MM',
    key: 'custrecord_fsd_depth_mm',
  },
  treatmentOrder: {
    title: 'Treatment Order',
    key: 'custrecord_fsd_treatment_order',
  },
  parcelName: {
    title: 'Parcel Name',
    key: 'custrecord_fsd_parcel_name',
  },
  fluorescence: {
    title: 'Fluorescence',
    key: 'custrecord_fsd_fluorescence',
  },
  parcelId: {
    title: 'Parcel ID',
    key: 'custrecord_fsd_parcel_id',
  },
  programType: {
    title: 'Program Type',
    key: 'custrecord_fsd_program_type',
  },
  girdle: {
    title: 'Girdle',
    key: 'custrecord_fsd_girdle',
  },
  caratsProduced: {
    title: 'Carats Produced',
    key: 'custrecord_fsd_carats_produced',
  },
  assemblyBuild: {
    title: 'Assembly Build',
    key: 'custrecord_fsd_assembly_build',
  },
  tint: {
    title: 'Tint',
    key: 'custrecord_fsd_tint',
  },
  lengthMm: {
    title: 'Length MM',
    key: 'custrecord_fsd_length_mm',
  },
  status: {
    title: 'Status',
    key: 'custrecord_fsd_status',
  },
} as const satisfies Record<string, DataTableHeader>

/**
 * Columns to request NetSuite return when fetching stones
 */
export const stoneColumns = Object.values(stonesHeadersMapping).map(
  (map: DataTableHeader) => map.key
)

/**
 * POLISHING
 */
const _fetchSummary = fetchNetSuiteSummary<NetSuiteSummaryData>
export const fetchStoneSummary = _fetchSummary.bind(null, stonesRoute)

export async function getPolishingDataModel() {
  return (await createClient()).get<PolishingDataModel>(
    `${polishingRoute}/data-model`
  )
}

export async function createPolishing(polishingData: PolishingPayloadItem) {
  return (await createClient()).post<PolishingCreationResponse>(
    polishingRoute,
    polishingData
  )
}

export async function fetchStones(
  params: StoneSearchParams,
  config: AxiosRequestConfig = {}
) {
  // Serialize specific fields
  // https://github.com/diamond-foundry/netsuite-account-customization-sandbox-1/blob/97955b6cb4f355ece0c0299e8e3a3ce8890d956b/src/FileCabinet/SuiteScripts/df/diamond-lifecycle/modules/df-diamond-lifecycle-search.js#L190
  config.params = serializeFieldsAsJSON(params, ['status', 'resultColumns'])
  const { data: nsResponse, ...response } = await (
    await createClient()
  ).get<NetSuiteSearchResponse<RawNetSuiteStone>>(stonesRoute, config)

  const stones = nsResponse.data.items.map(transformRawNetSuiteItem)

  return {
    response,
    pagination: nsResponse.data.info,
    data: stones,
  } as NetSuiteFetchResponse<RawStone[]>
}
