import { User as Auth0User } from '@auth0/auth0-spa-js'

// Typescript index types need to be literals i.e. we can't use claimNamespace
// as a constant that gets used in other types
export const claimNamespace = 'https://brilliant.dfoundry.co'

export enum Role {
  Administrator = 'Administrator',
  Sorter = 'Sorter',
  Polisher = 'Polisher',
  GemFeedbackHandler = 'GemFeedbackHandler',
  HR = 'HR',
}

export type RedirectCallback = (appState: any) => void

export type ClaimData = {
  roles: Role[]
  netsuite: {
    vendorId?: string
  }
  metadata: {
    title?: string
  }
}

export type User = Auth0User & {
  [claimNamespace]: ClaimData
}
