import type { AxiosError } from 'axios'
import type { Ref } from 'vue'

import type { AxiosClientResult } from '@/api/types'

export type useComponentStoreOptions = {
  /**
   * Whether to trigger a snack when an error occurs
   * @default true
   */
  triggerSnack?: boolean
  /** An external loading ref to use */
  loading?: Ref<boolean>
}

export type useSafeComponentStoreOptions = useComponentStoreOptions

const { triggerSnack } = useSnackbar()

/**
 * Keeps track of the last time a fetch was made and it's value.
 * @param fetchFn The function to call to fetch the data
 *
 * @example
 * const { data, loading, error, refresh } = useComponentStore(fetchResource)
 * refresh(123)
 *
 * @example
 * const { loading, error, refresh } = useComponentStore(postWithNoReturn)
 */
export function useComponentStore<
  T,
  A extends any[],
  E = T extends { error: infer E } ? E : unknown,
>(fetchFn: (...args: A) => Promise<T>, _options?: useComponentStoreOptions) {
  const options = {
    triggerSnack: true,
    ..._options,
  }

  const data = ref<T | null>()
  // Must cast to Ref when using generics per https://github.com/vuejs/core/issues/2136
  const error = ref<null | E>(null) as Ref<null | E>
  const loading = options.loading ?? ref(false)
  const fetchedAt = ref<Date>()

  const refresh = async (...args: A) => {
    loading.value = true
    try {
      const result = await fetchFn(...args)
      data.value = result
      fetchedAt.value = new Date()
    } catch (e: any) {
      error.value = e
      options.triggerSnack &&
        triggerSnack(e.message, { color: 'error', timeout: 10000 })
      // rethrow the error so it can be caught by Sentry or caller
      throw e
    } finally {
      loading.value = false
    }
  }

  return {
    data,
    error,
    loading,
    fetchedAt,
    refresh,
  }
}

// Use this to extract the data type from whatever
type TypeOfAxiosErrorData<E> = E extends AxiosError<infer D> ? D : never

/**
 * Keeps track of the last time a fetch was made and it's value.
 *
 * Similar to useComponentStore, but this one is tailored for use with the custom
 * `safe*` methods in `src/api/client.ts`. It expects the result to be an object
 * with a `data` and `error` property.
 *
 *
 * @param fetchFn The function to call to fetch the data
 *
 * @example
 * const { data, loading, error, refresh } = useComponentStore(fetchResource)
 * refresh(123)
 *
 * @example
 * const { loading, error, refresh } = useComponentStore(postWithNoReturn)
 */
export function useSafeComponentStore<
  T extends AxiosClientResult,
  A extends any[],
  E = TypeOfAxiosErrorData<T['error']>,
>(
  fetchFn: (...args: A) => Promise<T>,
  _options: useSafeComponentStoreOptions = {}
) {
  const options = {
    triggerSnack: true,
    ..._options,
  }

  const data = ref<T['data']>()
  const error = ref<T['error']>()
  const errorData = computed<E>(() => {
    const responseData = error.value?.response?.data
    return responseData?.data ? responseData.data : (responseData as any)
  })
  const loading = ref(false)
  const fetchedAt = ref<Date>()

  const refresh = async (...args: A) => {
    loading.value = true
    try {
      const { data: _data, error: _error } = await fetchFn(...args)

      data.value = _data
      error.value = _error
      fetchedAt.value = new Date()
    } catch (e: any) {
      data.value = null
      error.value = e
      options.triggerSnack &&
        triggerSnack(e.message, { color: 'error', timeout: 10000 })
      // rethrow the error so it can be caught by Sentry or caller
      throw e
    } finally {
      loading.value = false
    }
  }

  return {
    data,
    error,
    errorData,
    loading,
    fetchedAt,
    refresh,
  }
}
