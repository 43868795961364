<template lang="pug">
v-app
  v-layout
    //- Global App Bar
      Ordered to be beside the sidebar
      see: https://vuetifyjs.com/en/features/application-layout/#placing-components
    the-app-bar(:order='1' @navDrawerToggle='toggleNavDrawer')

    //- Global Sidebar
    v-navigation-drawer(permanent, :order='0', :rail='navDrawer')
      //- Sidebar Header
      v-list.pt-0
        v-list-item.mt-1.pt-3.pb-0(:class='{ "pt-6": !navDrawer }')
          router-link(to='/dashboard')
            BrilliantLogo(
              :icon='navDrawer',
              :fill-color='theme.global.current.value.dark ? "white" : "black"'
            )
      the-nav(:navItems='globalNavItems', :navDrawer='navDrawer')

    //- Controls the layout based on component state
    v-main
      v-container.h-100.pa-0.overflow-x-auto(fluid)
        //- Inline padding so it can be overwritten by the view component
        router-view(style='padding: 16px')

    the-footer
  the-snackbar
  the-version-checker

  ProcessingHud
</template>

<script lang="ts" setup>
import { useDisplay, useTheme } from 'vuetify'

import { roleGroups } from './auth/roles'
import type { NavItem } from './types'

const display = useDisplay()
const theme = useTheme()

const { useMiniSidebar } = usePreferences()

// Always default to true for small screens
const navDrawer = computed({
  get: () => display.mdAndDown.value || useMiniSidebar.value,
  set: value => (useMiniSidebar.value = value),
})
const toggleNavDrawer = () => {
  navDrawer.value = !navDrawer.value
}

const globalNavItems = [
  {
    label: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: { name: 'dashboard' },
  },
  {
    label: 'Activity',
    icon: 'mdi-inbox-multiple',
    to: { name: 'activity.index' },
    roleGroup: roleGroups.admin,
  },
  {
    label: 'Kafka Subscriptions',
    icon: 'mdi-newspaper',
    to: { name: 'kafka-subscriptions.index' },
    roleGroup: roleGroups.admin,
  },
  {
    label: 'BullMQ',
    icon: 'mdi-bullseye-arrow',
    to: { name: 'bullboard' },
    roleGroup: roleGroups.admin,
  },
  {
    label: 'UKG Sync Audit',
    icon: 'mdi-account-search',
    to: { name: 'ukg-sync.index' },
    roleGroup: roleGroups.hr,
  },
  {
    label: 'Gem Feedback',
    icon: 'mdi-comment-plus',
    to: { name: 'gem-feedback.index' },
    roleGroup: roleGroups.gemFeedback,
  },
  {
    label: 'Sorting',
    icon: 'mdi-shape-plus',
    to: { name: 'sorting' },
    roleGroup: roleGroups.sorting,
  },
  {
    label: 'Polishing',
    icon: 'mdi-diamond-stone',
    to: { name: 'polishing' },
    roleGroup: roleGroups.polishing,
  },
] as NavItem[]
</script>

<style>
/* Adding to the v-main__wrap class in order to make the container fill the height the way we want */
.v-main__wrap {
  display: flex;
}

/* TODO: Find a better place for this */
.cursor-pointer {
  cursor: pointer;
}
</style>
