import { createHead } from '@vueuse/head'
import type { PluginInstallFunction } from './types'

export const install: PluginInstallFunction = app => {
  const head = createHead({
    templateParams: {
      extraInfo: null,
      separator: '-',
    },
    titleTemplate:
      '%s %separator %extraInfo %separator Brilliant by Diamond Foundry',
    meta: [
      {
        charset: 'utf-8',
      },
      {
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0',
      },
      {
        'http-equiv': 'X-UA-Compatible',
        content: 'IE=edge',
      },
      {
        name: 'apple-mobile-web-app-status-bar-style',
        content: 'black-translucent',
      },
      { name: 'msapplication-TileColor', content: '#a32322' },
      { name: 'theme-color', content: '#a32322' },
    ],
  })

  app.use(head)
}
