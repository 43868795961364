import type { Order as ShopifyOrder } from '@shopify/shopify-api/rest/admin/2024-04'
import type { AxiosRequestConfig } from 'axios'

import { createClient } from './client'
import {
  fetchResources,
  fetchResource,
  updateResource,
  fetchSummary,
} from './generic-crud'
import type {
  ExternalMetadata,
  BrilliantEvent,
  BrilliantSummaryData,
  BrilliantGenericError,
  BrilliantResource,
  BrilliantType,
} from './types'

const baseRoute = '/orders'

type OrderKeys =
  | 'id'
  | 'order_number'
  | 'created_at'
  | 'customer'
  | 'shipping_address'
  | 'billing_address'
  | 'line_items'

  // Customer notes
  | 'note'
  | 'note_attributes'

  // Order status chips
  | 'source_name'
  | 'financial_status'
  | 'fulfillment_status'
  | 'cancelled_at'

export type OrderData = Pick<ShopifyOrder, OrderKeys>

export type StatusData = BrilliantType

export type Order = BrilliantResource<OrderData> & {
  data?: OrderData
  erpMetadata?: ExternalMetadata
  sourceMetadata: ExternalMetadata
  returnMetadata: ExternalMetadata[]
  processingStatus: StatusData
  events?: BrilliantEvent[]
}

// TODO: We can restrict the type to just the statuses we know exist
export type OrderSummary = BrilliantSummaryData

export type PropertyValidationCheck = {
  /** The internal name/key of the validation step */
  key: string
  /** The NetSuite field id */
  fieldId?: string
  /**
   * The path to the property on the order data
   * Note: May reference an internal object
   */
  path?: string
  /** A set of checks performed by the validation step */
  validation?: Record<string, boolean>
  /** The value of the property on the order data or used by validator */
  value: any
  /** False if any checks fail */
  isValid: boolean
  /** A largely unstructured object representing the reason why a step failed */
  error: {
    /** A human readable description of the cause */
    message: string
    [x: string]: string | string[] | boolean
  }
}

export type LineItemValidationCheck = {
  /** The ID of the shopify LineItem */
  id: number
  /** The internal name/key of the validation step */
  key: 'lineItems'
  /** An internal NetSuite property */
  sublistId: 'item'
  /** Will be in the format line_item[<index>] */
  path: `line_item[${number}]`
  error: PropertyValidationCheck[]
}

export type OrderValidationEvent = BrilliantEvent<
  {
    message: string
    data: Array<PropertyValidationCheck | LineItemValidationCheck>
  },
  'erp_import_failed'
>

/**
 * Orders API
 */
export const orderErrorStatuses = [
  'brilliant_processing_error',
  'erp_import_error',
]
export const orderSuccessStatuses = ['erp_import_complete']

const _fetchSummary = fetchSummary<OrderSummary>
export const fetchOrderSummary = _fetchSummary.bind(null, baseRoute)

//#region -- Fetch Orders
// Separately assigning the function is necessary to type it correctly
const _fetchAll = fetchResources<Order[]>
/**
 * Fetch orders from Brilliant
 */
export const fetchOrders = _fetchAll.bind(null, baseRoute)
//#endregion

//#region -- Fetch Order
// Separately assigning the function is necessary to type it correctly
const _fetchOne = fetchResource<Order>
/**
 * Fetch a specific order from Brilliant
 */
export const fetchOrder = _fetchOne.bind(null, baseRoute)
//#endregion

//#region -- Update Order
// Separately assigning the function is necessary to type it correctly
const _putOrder = updateResource<Order>
/**
 * Update a specific order from Brilliant
 */
export const updateOrder = _putOrder.bind(null, baseRoute)
//#endregion

export async function dismissOrder(
  id: string | number,
  config: AxiosRequestConfig = {}
) {
  const client = await createClient()
  return client.safeGet<Order>(`${baseRoute}/${id}/dismiss`, config)
}

/**
 * Send order data to the ERP
 *
 *
 * @param id - The ID of the order to send
 * @param newData - The data to send to the ERP
 *
 *
 */
export async function postToERP(
  id: number | string,
  newData: any,
  config: AxiosRequestConfig = {}
) {
  const client = await createClient()

  const result = await client.safePost<
    {
      erpResponse: any
      order: Order
    },
    BrilliantGenericError<{ errors: string[] }>
  >(`${baseRoute}/${id}/send-to-erp`, newData, config)

  if (result.error) return result

  const { erpResponse, order } = result.data
  return {
    ...result,
    erpResponse,
    // Retain the original order data, but update the metadata
    data: { ...order, data: newData },
  }
}
