/**
 * The store handles the computed state and actions for the order page
 */
export const useGlobalStore = defineStore('global', () => {
  const logger = useLogger('useGlobalStore')

  /** Covers the UI in a global loading modal */
  const isProcessing = ref(false)
  const setProcessing = (newState: boolean) => (isProcessing.value = newState)

  return {
    isProcessing: readonly(isProcessing),
    setProcessing,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
