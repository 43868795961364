import '@/styles/main.scss'
import { createVuetify } from 'vuetify'

import type { PluginInstallFunction } from './types'

import LoopLogo from '@/components/svg/LoopLogo.vue'
import NetSuiteLogo from '@/components/svg/NetSuiteLogo.vue'
import ShopifyLogo from '@/components/svg/ShopifyLogo.vue'
import { themes } from '@/utils'

const { useDarkMode } = usePreferences()

const settings = {
  theme: {
    defaultTheme: useDarkMode.value ? 'dark' : 'light',
    themes,
    options: { customProperties: true },
  },
  icons: {
    aliases: {
      loopLogo: LoopLogo,
      netsuiteLogo: NetSuiteLogo,
      shopifyLogo: ShopifyLogo,
    },
  },
}

export const install: PluginInstallFunction = app => {
  const vuetify = createVuetify({
    ...settings,
  })
  app.use(vuetify)
}
