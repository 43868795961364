<template lang="pug">
v-snackbar(
  ref='snackbar',
  :color='currentSnack?.color'
  app
  bottom,
  :model-value='_active',
  :timeout='currentSnack?.timeout ?? 5000'
)
  span {{ currentSnack?.message }}

  template(#actions)
    v-btn(
      v-if='currentSnack?.action'
      variant='text'
      @click='currentSnack.action.handler'
    )
      span {{ currentSnack.action.label }}
    v-btn(v-else icon @click='closeSnack')
      v-icon mdi-close
</template>

<script lang="ts" setup>
import { invoke, type VueInstance } from '@vueuse/core'
const { _active, closeSnack, currentSnack, snacks } = useSnackbar()
const { emitSnackClosed, emitSnackOpened } = useSnackbarEvents()

const snackbar = ref<VueInstance>()
invoke(async () => {
  // Watch until the snackbar is mounted then stop watching
  await until(snackbar).toBeTruthy()
  console.assert(snackbar.value)

  // HACK: Access the .v-snackbar__wrapper element to be able to listen
  //       for the transitionend event and filter events from children.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  snackbar.value?.$el.addEventListener('transitionend', e => {
    if (
      // Explicitly ignore events from children
      e.eventPhase !== e.AT_TARGET ||
      !(e instanceof TransitionEvent) ||
      // NOTE: The snackbar uses both opacity and transform transitions
      //       so we use just the opacity transition to determine when
      //       the snackbar is fully visible or hidden.
      e.propertyName !== 'opacity'
    )
      return
    if (_active.value) {
      emitSnackOpened()
    } else {
      emitSnackClosed()
    }
  })
})
</script>
