<template lang="pug">
v-footer(
  :color='environment.currentConfig?.color || "dark"'
  order='-1'
  app
)
  div ©{{ currentYear }} Diamond Foundry Inc.

  v-spacer

  div v{{ version }}-{{ commitRef }}-
    span.text-decoration-underline(
      style='text-decoration-style: dotted !important',
      :title='humanReadableTimestamp'
      data-test='build-timestamp'
    ) {{ buildTimestamp }}

  .environment-select-wrapper.d-flex(v-if='hasAccess(adminGroup)')
    v-divider.mx-4(vertical)
    v-select.environment-select.flex-grow-0(
      :items='environment.configs'
      hide-details
      single-line,
      variant='underlined'
      :model-value='environment.currentConfig'
      @update:model-value='environment.goto'
    )
</template>

<script lang="ts" setup>
import { formatISODate } from '@/utils'

const environment = useEnvironment()

const { roleGroups, hasAccess } = useAuth()
const adminGroup = roleGroups.admin

const currentYear = new Date().getFullYear()
const version = import.meta.env.VITE_VERSION
const commitRef = import.meta.env.VITE_COMMIT_REF
const buildTimestamp = import.meta.env.VITE_BUILD_TIMESTAMP
const humanReadableTimestamp = formatISODate(new Date(Number(buildTimestamp)))
</script>

<style lang="scss" scoped></style>
