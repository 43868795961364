<template lang="pug">
v-snackbar(
  ref='snackbar'
  v-model='isActive'
  color='info'
  app
  bottom
  right
  timeout='-1'
)
  span {{ snackConfig.message }}

  template(#actions)
    v-btn(variant='text' @click='snackConfig.action.handler')
      span {{ snackConfig.action.label }}
</template>

<script lang="ts">
export default {
  name: 'TheVersionChecker',
}
</script>

<script lang="ts" setup>
import axios from 'axios'

/** @see /netlify/plugins/check-version.ts */
export type CheckVersionResponse = {
  deployId: string
  idMatch?: boolean
}

const snackConfig = {
  message: 'A new version of the app is available!',
  action: {
    label: 'Refresh',
    handler: () => window.location.reload(),
  },
}

/** The deploy id set at build time */
const localDeployId = import.meta.env.VITE_DEPLOY_ID

/** The interval length for checking the version in milliseconds. */
const intervalLength = 1000 * 60 * 5 // 5 minutes

const isActive = ref(false)
const versionResponse = ref<CheckVersionResponse>()

const checkVersion = async () => {
  const response = await axios.get<CheckVersionResponse>(
    `/check-version/${localDeployId}`
  )

  if (response.data.idMatch === false) {
    checkInterval.pause()
    versionResponse.value = response.data
    isActive.value = true
  }
}
/** @see https://vueuse.org/shared/useIntervalFn/ */
const checkInterval = useIntervalFn(checkVersion, intervalLength, {
  immediate: true,
  immediateCallback: true,
})

const onFocusHandler = () => {
  if (checkInterval.isActive.value) return
  checkInterval.resume()
}

const onBlurHandler = () => {
  checkInterval.pause()
}

onMounted(() => {
  window.addEventListener('focus', onFocusHandler)
  window.addEventListener('blur', onBlurHandler)
})

onUnmounted(() => {
  window.removeEventListener('focus', onFocusHandler)
  window.removeEventListener('blur', onBlurHandler)
})
</script>
