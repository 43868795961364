import { createAuth0 } from '@auth0/auth0-vue'
import { setUser as setSentryUser } from '@sentry/vue'
import type { PluginInstallFunction } from './types'

// Provide access to the auth0 instance outside of the inject/provide system
// https://github.com/auth0/auth0-vue/issues/99#issuecomment-1099704276
export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirect_uri: window.location.origin,
  },
})

export const install: PluginInstallFunction = app => {
  app.use(auth0)

  // When the user logs in update the Sentry user
  whenever(auth0.isAuthenticated, () => setSentryUser(auth0.user))
}
