<template lang="pug">
//- Title Bar
v-app-bar(app elevation=2)
  //- Toggle sidebar mini state
  v-app-bar-nav-icon(@click.stop='$emit("navDrawerToggle")')

  //- Page title
  v-app-bar-title {{ pageTitle }}

  v-spacer

  //- User data and management
  v-menu(
    v-model='showProfileCard'
    offset='4'
    location='bottom',
    :close-on-content-click='false'
  )
    template(#activator='{ props }')
      v-btn.mr-4(v-bind='props' icon='mdi-account' elevation='2')

    //- User Profile Card
    v-card.pt-4(min-width='200')
      v-list.text-center.pb-0
        //- User Avatar
        v-list-item
          v-avatar(color='primary' size='88')
            span.text-white.text-h4 {{ userInitials }}

        //- User data
        v-list-item(selectable)
          v-list-item-title {{ user?.name }}
          v-list-item-subtitle {{ metadata?.title ? metadata.title : user?.email }}

        //- Roles
        v-list-item(selectable)
          v-list-item-title Roles
          v-list-item-subtitle(v-if='roles') {{ roles.join(', ') }}

        v-divider.mt-3

        //- Dark mode toggle
        v-list-item.mx-auto(max-width='165')
          v-switch(
            v-model='isDarkMode'
            color='primary'
            inset
            hide-details
            prepend-icon='mdi-weather-sunny'
            append-icon='mdi-weather-night'
          )

      v-divider
      v-card-actions.justify-center
        v-btn.px-8(x-large variant='text' block @click='logout') Sign out
</template>

<script lang="ts" setup>
import type { User } from '@/auth/types'
import { useDisplay, useTheme } from 'vuetify'

defineEmits<{
  (event: 'navDrawerToggle'): void
}>()

//#region User Profile Card
const theme = useTheme()

const { auth0, user, roles } = useAuth()
const { currentRoute } = useRouter()
const { useDarkMode } = usePreferences()

const showProfileCard = ref(false)

const pageTitle = computed(() => {
  return currentRoute.value.matched.map(match => match.meta?.title).join(' / ')
})
const isDarkMode = computed({
  get: () => theme.global.current.value.dark,
  set: (value: boolean) => {
    theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    useDarkMode.value = value
  },
})

const metadata = computed(() => auth0.idTokenClaims.value?.metadata)
const userInitials = computed(() => {
  const user = unref(auth0.user)
  return user?.name
    ? user.name
        .split(' ')
        .map(name => name.substr(0, 1))
        .join('')
    : user?.email?.substr(0, 2).toUpperCase() ?? '--'
})

const logout = () => {
  auth0.logout()
}
//#endregion
</script>

<style>
.environment-select {
  width: 100px;
}
</style>
