import { type AxiosRequestConfig } from 'axios'

import createClient from './client'
import type {
  BrilliantFetchResult,
  BrilliantCreateResult,
  BrilliantUpdateResult,
  BrilliantDeleteResult,
  BrilliantSearchParams,
  BrilliantResponse,
  BrilliantSummaryResult,
  NetSuiteResponse,
  BrilliantGenericError,
} from './types'

/**
 * Fetch resource summary data with the Brilliant API
 * @example
 * const fetchOrderSummary = fetchSummary<OrderSummaryData>.bind(null, '/orders')
 */
export async function fetchSummary<T>(
  baseRoute: string,
  params?: BrilliantSearchParams,
  config: AxiosRequestConfig = {}
) {
  config.params = params
  const client = await createClient()
  return client.safeGet<T, BrilliantGenericError, BrilliantSummaryResult<T>>(
    `${baseRoute}/summary`,
    config
  )
}

/**
 * Fetch summary data from NetSuite with the Brilliant API
 * @example
 * const fetchOrderSummary = fetchNetSuiteSummary<OrderSummaryData>.bind(null, '/orders')
 */
export async function fetchNetSuiteSummary<T>(
  baseRoute: string,
  params?: BrilliantSearchParams,
  config: AxiosRequestConfig = {}
) {
  config.params = params
  const client = await createClient()
  return client.safeGet<
    NetSuiteResponse<T>,
    BrilliantGenericError,
    BrilliantSummaryResult<NetSuiteResponse<T>>
  >(`${baseRoute}/summary`, config)
}

/**
 * Create a new resource with the Brilliant API
 * @example
 * const _create = createResource<Order>
 * const createOrder = _create.bind(null, '/orders')
 * or
 * const createOrder = (createResource<Order>).bind(null, '/orders')
 */
export async function createResource<D, T>(
  baseRoute: string,
  payload: D,
  config: AxiosRequestConfig = {}
) {
  const client = await createClient()
  return client.safePost<T, BrilliantGenericError, BrilliantCreateResult<T>>(
    `${baseRoute}`,
    payload,
    config
  )
}

/**
 * Fetch resources with the Brilliant API
 * @example
 * const fetchOrders = fetchResources<Order[]>.bind(null, '/orders')
 */
export async function fetchResources<T>(
  baseRoute: string,
  params?: BrilliantSearchParams,
  config: AxiosRequestConfig = {}
) {
  config.params = params
  const client = await createClient()
  const result = await client.safeGet<BrilliantResponse<T>>(
    `${baseRoute}`,
    config
  )
  if (result.error) return result

  const data = result.data
  return {
    ...result,
    // FIXME: pagination is thrown away by the `useSafeComponentStore` composable
    // @see: BRILL-297
    pagination: data.meta,
    data: data.data,
  } as BrilliantFetchResult<T>
}

/**
 * Fetch a specific resource with the Brilliant API
 * @example
 * const fetchOrder = fetchResource<Order>.bind(null, '/orders')
 */
export async function fetchResource<T>(
  baseRoute: string,
  id: string | number,
  config: AxiosRequestConfig = {}
) {
  const client = await createClient()
  return client.safeGet<T, BrilliantGenericError, BrilliantFetchResult<T>>(
    `${baseRoute}/${id}`,
    config
  )
}

/**
 * Update a resource with the Brilliant API
 * @example
 * const updateOrder = updateResource<Order[]>.bind(null, '/orders')
 */
export async function updateResource<T>(
  baseRoute: string,
  id: string | number,
  payload: Partial<T>,
  config: AxiosRequestConfig = {}
) {
  const client = await createClient()
  return client.safePut<T, BrilliantGenericError, BrilliantUpdateResult<T>>(
    `${baseRoute}/${id}`,
    payload,
    config
  )
}

/**
 * Generic delete function for Brilliant resources
 * @example
 * const deleteOrder = deleteResource<Resource>.bind(null, '/orders')
 */
export async function deleteResource<T>(
  baseRoute: string,
  id: string | number,
  config: AxiosRequestConfig = {}
) {
  const client = await createClient()
  return client.safeDelete<T, BrilliantGenericError, BrilliantDeleteResult<T>>(
    `${baseRoute}/${id}`,
    config
  )
}
