enum Environment {
  Prod = 'prod',
  SB1 = 'sb1',
  SB3 = 'sb3',
  Dev = 'dev',
}

const environmentConfigs = [
  {
    title: 'Prod',
    value: Environment.Prod,
    protocol: 'https',
    host: 'brilliant.dfoundry.co',
    color: '', // Will use whatever color is set in the theme
  },
  {
    title: 'SB1',
    value: Environment.SB1,
    protocol: 'https',
    host: 'staging--brilliant-dfoundry-co.netlify.app',
    color: '#023ead', // Blue (Schools : Big East : Seton Hall University)
  },
  // {
  //   title: 'SB3',
  //   value: Environment.SB3,
  //   protocol: 'https',
  //   host: 'staging--brilliant-dfoundry-co.netlify.app',
  //   color: '#663399', // Purple (Schools : Big 12 : Kansas State)
  // },
  {
    title: 'Dev',
    value: Environment.Dev,
    protocol: 'http',
    host: 'localhost:3000',
    color: '#ff9c59', // Orange (Creamcicle)
  },
]

const originMap = {
  'https://brilliant.dfoundry.co': Environment.Prod,
  'https://staging--brilliant-dfoundry-co.netlify.app': Environment.SB1,
  'http://localhost:3000': Environment.Dev,
  'http://localhost:8888': Environment.Dev,
}

// These can be static because we're not able to change the origin while retaining the same session

const currentEnv = (() => {
  const origin = window.location.origin
  const environment = Object.entries(originMap).find(
    ([origin]) => window.location.origin === origin
  )?.[1]
  console.assert(environment, `Unknown origin: ${origin}`)
  return environment
})()

const currentConfig = environmentConfigs.find(
  config => currentEnv === config.value
)

const goToEnvironment = (environment: Environment) => {
  const config = environmentConfigs.find(config => environment === config.value)
  if (config) {
    const newUrl = new URL(location.href)
    newUrl.protocol = config.protocol
    newUrl.port = '' // clear port so host can set if needed
    newUrl.host = config.host

    location.assign(newUrl)
  }
}

export const useEnvironment = () => {
  return {
    envs: Environment,
    configs: environmentConfigs,
    current: currentEnv,
    currentConfig,
    goto: goToEnvironment,
  }
}
