import type { TracePropagationTargets } from '@sentry/types'
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import type { PluginInstallFunction } from './types'

type BrowserTracingOptions = NonNullable<
  ConstructorParameters<typeof Sentry.BrowserTracing>[0]
>

type BrowserTracingOptions = NonNullable<
  ConstructorParameters<typeof Sentry.BrowserTracing>[0]
>

// Sentry configuration
const tracePropagationTargets: TracePropagationTargets = [
  'localhost',
  ...(import.meta.env.VITE_BASE_API_PATH
    ? [import.meta.env.VITE_BASE_API_PATH]
    : []),
  /^\//,
]

export const install: PluginInstallFunction = (app, router: Router) => {
  if (import.meta.env.VITE_SENTRY_INITIALIZE !== 'false')
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'development',
      enabled: import.meta.env.VITE_SENTRY_DISABLED !== 'false',
      debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/integrations/vue-router/
      trackComponents: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      tracePropagationTargets,
    })
}
